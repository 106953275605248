import * as React from "react"
import { useForm, FormProvider } from "react-hook-form"
import FileInputField from "../FileInputField"
import SelectField from "../SelectField"
import ComponentSelectField from "../ComponentSelectField"

import { yupResolver } from "@hookform/resolvers/yup"
import * as yup from "yup"
import { useFormStepperContext } from "../FormStepper/FormStepperProvider"
import Button from "../Button"
import images from "../../images"
import PositionSelection, { FRONT_POSITIONS } from "../PositionSelectionField"
import TextAreaField from "../TextAreaField"
import InputField from "../InputField"

const schema = yup.object().shape({
  option: yup.string().required("Wähle eine Option aus"),
  material: yup.string().when("option", {
    is: val => val,
    then: yup.string().required("Wähle eine Option aus"),
  }),
  milFunction: yup.mixed().when("option", {
    is: "milFunction",
    then: yup.string().required("Darf nicht leer sein"),
  }),
  color: yup.mixed().when("option", {
    is: "milFunction",
    then: yup.string().required("Darf nicht leer sein"),
  }),
  file: yup.mixed().when("option", {
    is: "individual",
    then: yup
      .mixed()
      .test(
        "fileCount",
        "Bitte wähle eine Datei aus",
        value => value?.length === 1
      ),
  }),
  additionalDesigns: yup.array().of(
    yup.object().shape(
      {
        file: yup.mixed().when(["note"], {
          is: note => !note,
          then: yup
            .mixed()
            .required("Bitte Datei hochladen oder Beschreibung ausfüllen"),
        }),
        note: yup.string().when(["file"], {
          is: file => !file || file.length === 0,
          then: yup
            .string()
            .required("Bitte Datei hochladen oder Beschreibung ausfüllen"),
        }),
      },
      [["file", "note"]]
    )
  ),
})

export default function FrontSideStep({ values }) {
  const methods = useForm({
    resolver: yupResolver(schema),
    defaultValues: values,
  })

  const frontOption = methods.watch("option")
  const frontPosition = methods.watch("position")

  const { setValues, next, previous } = useFormStepperContext() || {}

  function onNext(data) {
    setValues(data)
    next()
  }

  const FILTERED_POSITION = React.useMemo(() => {
    switch (frontPosition) {
      case "LEFT":
        return ["FRONT_TOP_LEFT"]
      case "RIGHT":
        return ["FRONT_TOP_RIGHT"]
      default:
        return []
    }
  }, [frontPosition])

  function renderSelectionForm() {
    return (
      <form onSubmit={methods.handleSubmit(onNext)}>
        <div className="grid grid-cols-1 gap-6">
          <ComponentSelectField
            name="option"
            label="Option"
            renderItem={option => (
              <img alt={`${option.label}`} width="300" src={option.image} />
            )}
            className="grid grid-cols-2 md:grid-cols-2 l:grid-cols-3 xl:grid-cols-3 gap-2"
            options={[
              {
                label: "Funktionsabzeichen",
                value: "milFunction",
                image: images.frontFunction,
              },
              {
                label: "Individuelles Design",
                value: "individual",
                image: images.frontIndividual,
              },
            ]}
          />

          <SelectField
            name="material"
            label="Veredelungsverfahren"
            options={[
              {
                label: "Druck",
                value: "DRUCK",
              },
              {
                label: "Stick",
                value: "STICK",
              },
            ]}
          />

          {frontOption === "milFunction" && (
            <>
              <SelectField
                fullWidth
                name="milFunction"
                label="Funktionsabzeichen"
                order
                information={() => (
                  <>
                    <span>
                      Eine Übersicht der Funktionsabzeichen findest du{" "}
                    </span>
                    <a
                      rel="noreferrer"
                      href={
                        "https://www.vtg.admin.ch/de/die-schweizer-armee/mittel/militaerische-abzeichen/funktions-und-spezialistenabzeichen.html#ui-collapse-744"
                      }
                      target="_blank"
                      className="underline text-indigo-600"
                    >
                      hier
                    </a>
                  </>
                )}
                options={[
                  { label: "Aufklärungssoldat", value: "Aufklärungssoldat" },
                  { label: "Infanterist", value: "Infanterist" },
                  { label: "Gebirgsspezialist", value: "Gebirgsspezialist" },
                  {
                    label: "Minenwerferkanonier",
                    value: "Minenwerferkanonier",
                  },
                  {
                    label: "Armeeaufklärungsdetachement",
                    value: "Armeeaufklärungsdetachement",
                  },
                  { label: "Trompeter", value: "Trompeter" },
                  { label: "Schlagzeuger", value: "Schlagzeuger" },
                  { label: "Panzerjägersoldat", value: "Panzerjägersoldat" },
                  { label: "Kanonier", value: "Kanonier" },
                  {
                    label: "Lenkwaffensoldat RAPIER",
                    value: "Lenkwaffensoldat RAPIER",
                  },
                  { label: "Radarsoldat", value: "Radarsoldat" },
                  {
                    label: "Flugplatzstabskompanie",
                    value: "Flugplatzstabskompanie",
                  },
                  {
                    label: "Flugplatz und Lufttransportsicherung",
                    value: "Flugplatz und Lufttransportsicherung",
                  },
                  {
                    label: "Fallschirmaufklärer",
                    value: "Fallschirmaufklärer",
                  },
                  { label: "FULW Soldat", value: "FULW Soldat" },
                  {
                    label: "Lufttransportkompanie / Lufttransportstaffel",
                    value: "Lufttransportkompanie / Lufttransportstaffel",
                  },
                  {
                    label: "Lufttransport Supportkompanie",
                    value: "Lufttransport Supportkompanie",
                  },
                  { label: "Drohnengeschwader", value: "Drohnengeschwader" },
                  { label: "Baumaschinenfahrer", value: "Baumaschinenfahrer" },
                  { label: "Pontonier", value: "Pontonier" },
                  { label: "Rammpontonier", value: "Rammpontonier" },
                  { label: "Sappeur", value: "Sappeur" },
                  { label: "Funkaufklärer", value: "Funkaufklärer" },
                  { label: "Richtstrahlpionier", value: "Richtstrahlpionier" },
                  {
                    label: "Strategischer Funkaufklärer",
                    value: "Strategischer Funkaufklärer",
                  },
                  {
                    label: "Werksicherheitssoldat",
                    value: "Werksicherheitssoldat",
                  },
                  { label: "Sanitätssoldat", value: "Sanitätssoldat" },
                  { label: "Veterinärsoldat", value: "Veterinärsoldat" },
                  { label: "Hundeführer", value: "Hundeführer" },
                  { label: "Feldpost", value: "Feldpost" },
                  { label: "Truppenbuchhalter", value: "Truppenbuchhalter" },
                  { label: "Gerätemechaniker", value: "Gerätemechaniker" },
                  { label: "Panzermechaniker", value: "Panzermechaniker" },
                  { label: "Motorfahrer", value: "Motorfahrer" },
                  {
                    label: "Infrastruktursicherheitssoldat",
                    value: "Infrastruktursicherheitssoldat",
                  },
                  { label: "Nachschubsoldat", value: "Nachschubsoldat" },
                  { label: "Rettungssoldat", value: "Rettungssoldat" },
                  { label: "ABC Soldat", value: "ABC Soldat" },
                  { label: "Militärjustiz", value: "Militärjustiz" },
                  { label: "Präsident", value: "Präsident" },
                  { label: "KOKAMIR", value: "KOKAMIR" },
                  { label: "Büroordonnanz", value: "Büroordonnanz" },
                  { label: "Grenadier (SK)", value: "Grenadier (SK)" },
                  {
                    label: "Führungsstaffelsoldat",
                    value: "Führungsstaffelsoldat",
                  },
                  {
                    label: "Infanteriebesatzer / Infanteriepanzerfahrer",
                    value: "Infanteriebesatzer / Infanteriepanzerfahrer",
                  },
                  { label: "Grenadier", value: "Grenadier" },
                  { label: "Sicherungssoldat", value: "Sicherungssoldat" },
                  { label: "Sportsoldat", value: "Sportsoldat" },
                  { label: "Tambour", value: "Tambour" },
                  { label: "Panzersoldat", value: "Panzersoldat" },
                  { label: "Wettersoldat", value: "Wettersoldat" },
                  { label: "Kanonier (Flab)", value: "Kanonier (Flab)" },
                  {
                    label: "Lenkwaffensoldat STINGER",
                    value: "Lenkwaffensoldat STINGER",
                  },
                  { label: "Fliegerschwader", value: "Fliegerschwader" },
                  { label: "Flugplatzsappeur", value: "Flugplatzsappeur" },
                  { label: "Fliegersoldat", value: "Fliegersoldat" },
                  {
                    label: "FULW Gerätemechaniker",
                    value: "FULW Gerätemechaniker",
                  },
                  {
                    label: "FULW Sicherungssoldat",
                    value: "FULW Sicherungssoldat",
                  },
                  {
                    label: "Lufttransport Logistikkompanie",
                    value: "Lufttransport Logistikkompanie",
                  },
                  {
                    label: "Flugzeuginstandhaltungs- kompanie",
                    value: "Flugzeuginstandhaltungs- kompanie",
                  },
                  { label: "Baupionier", value: "Baupionier" },
                  { label: "Bootsschütze", value: "Bootsschütze" },
                  { label: "Panzersappeur", value: "Panzersappeur" },
                  {
                    label: "Übermittlungssoldat",
                    value: "Übermittlungssoldat",
                  },
                  {
                    label: "Nachrichtensoldat / Vermesser",
                    value: "Nachrichtensoldat / Vermesser",
                  },
                  { label: "Informatikpionier", value: "Informatikpionier" },
                  { label: "Sekretär", value: "Sekretär" },
                  { label: "Werkschutzsoldat", value: "Werkschutzsoldat" },
                  { label: "Werktechniksoldat", value: "Werktechniksoldat" },
                  { label: "Spitalsoldat", value: "Spitalsoldat" },
                  { label: "Hufschmied", value: "Hufschmied" },
                  { label: "Trainsoldat", value: "Trainsoldat" },
                  { label: "Offiziersordonnanz", value: "Offiziersordonnanz" },
                  { label: "Truppenkoch", value: "Truppenkoch" },
                  { label: "Motormechaniker", value: "Motormechaniker" },
                  { label: "Waffenmechaniker", value: "Waffenmechaniker" },
                  {
                    label: "Infrastrukturschutzsoldat",
                    value: "Infrastrukturschutzsoldat",
                  },
                  {
                    label: "Infrastrukturtechniksoldat",
                    value: "Infrastrukturtechniksoldat",
                  },
                  { label: "Rettungsgerätewart", value: "Rettungsgerätewart" },
                  {
                    label: "Militärpolizist / Verkehrssoldat",
                    value: "Militärpolizist / Verkehrssoldat",
                  },
                  { label: "Auditor", value: "Auditor" },
                  { label: "Gerichtsschreiber", value: "Gerichtsschreiber" },
                  {
                    label: "Untersuchungsrichter",
                    value: "Untersuchungsrichter",
                  },
                  { label: "Betriebssoldat", value: "Betriebssoldat" },
                  {
                    label: "Dienstchef Armeeseelsorger",
                    value: "Dienstchef Armeeseelsorger",
                  },
                ]}
              />

              <InputField
                fullWidth
                name="color"
                label="Farbe"
                placeholder="z.B. schwarz"
              />
            </>
          )}

          {frontOption === "individual" && (
            <FileInputField
              fullWidth
              name="file"
              label="Design hochladen"
              multiple
            />
          )}

          <SelectField
            fullWidth
            name="position"
            label="Position"
            options={[
              { label: "Links", value: "LEFT" },
              { label: "Rechts", value: "RIGHT" },
              { label: "Mitte", value: "CENTER" },
            ]}
          />

          {frontOption && (
            <PositionSelection
              name="additionalDesigns"
              label="Zusätzliche individuelle Designs"
              views={FRONT_POSITIONS}
              filteredPositions={FILTERED_POSITION}
              information={
                "Klicke auf die Stelle, wo das Design platziert werden soll."
              }
            />
          )}

          <TextAreaField name="notes" label="Bemerkungen" rows="3" />

          <div className="flex justify-between mt-8">
            <Button
              type="button"
              className="justify-self-end"
              label="Zurück"
              onClick={previous}
              variant="text"
            />

            <Button type="submit" className="justify-self-end" label="Weiter" />
          </div>
        </div>
      </form>
    )
  }

  return <FormProvider {...methods}>{renderSelectionForm()}</FormProvider>
}
